import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/position';

const LPPositionRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../LPPosition')),
  name: 'LPPosition',
};

export default LPPositionRoute;
