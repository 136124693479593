import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/xGalo/dividends';

const DividendsRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../Dividends')),
  name: 'Dividends',
};

export default DividendsRoute;
