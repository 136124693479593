import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/air-drop';

const LandingPageRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../LandingPage')),
  name: 'LandingPage',
};

export default LandingPageRoute;
