import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/xGalo';

const xGaloRoute: IRouteProps = {
  path: route,
  exact: true,

  component: lazy(() => import('../xGalo')),
  name: 'xGalo',
};

export default xGaloRoute;
