import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/liquidity-partners/:id';

const LiquidityPartnerDetailRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../LiquidityPartner')),
  name: 'LiquidityPartnerDetail',
};

export default LiquidityPartnerDetailRoute;
